<template>
  <div class="vocabContainer">
    <div class="vocabularyCard">
      <h2>{{ $t("flashCard.i18n15") }}</h2>
      <h2 class="timeSpent">
        {{ $t("flashCard.i18n16") }}
        {{ instant.formatSecondToMMSS(practiceDuring) }}
        {{ $t("flashCard.i18n17") }}
        <b>{{ vocabularyCount }}</b>
        {{ $t("flashCard.i18n18") }}
      </h2>
    </div>
    <div class="footer">
      <div class="goBack" @click="backToPreviousPage">{{ $t("flashCard.i18n19") }}</div>
      <div class="restart" @click="$emit('restart')">{{ $t("flashCard.i18n20") }}</div>
    </div>
  </div>
</template>

<script>
import { instant } from "@ivy-way/material";

export default {
  props: {
    vocabList: {
      type: Object,
      default: () => {}
    },
    practiceDuring: {
      type: Number,
      default: 0
    }
  },
  computed: {
    instant() {
      return instant;
    },
    pageTitle() {
      return this.vocabList.list?.title || "";
    },
    vocabularyCount() {
      return this.vocabList.list?.count || 0;
    }
  },
  methods: {
    backToPreviousPage() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped lang="scss">
.vocabContainer {
  width: 100vw;
}

.vocabularyCard {
  width: 100vw;
  margin-top: -40px;
  height: calc(100vh - 60px - 96px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0px 12px;
  h2 {
    color: var(--themeColor);
    font-size: 52px;
  }

  .timeSpent {
    font-size: 32px;
  }
}

.footer {
  height: 96px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  div {
    cursor: pointer;
    border: 1px solid #e0e0e0;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
  }

  div:hover {
    opacity: 0.5;
  }

  .restart {
    border-top: 12px solid #5Bc0DE;
    color: #5Bc0DE;
  }

  .goBack {
    border-top: 12px solid #42A16A;
    color: #42A16A;
  }
}
@media (max-width: 992px) {
  .vocabContainer {
    margin-top: 40px;
  }
}
</style>
