<template>
  <div>
    <el-drawer
      class="drawer"
      :visible.sync="opened"
      direction="rtl"
      :before-close="() => $emit('closeCollectVocabDrawer')"
    >
      <template slot="title">
        <div class="drawerTitle">
          {{ $t("flashCard.i18n3") }}
        </div>
      </template>
      <div v-if="isFreeMember" class="hasNoPermission">
        <span class="lock">
          <i class="fas fa-lock" />
        </span>
        <h3 class="upgradeText">
          {{ $t("message.upgrade_to") }}
          <router-link :to="{ name: 'ToeflPlans' }">{{ $t("message.pro_plan") }}</router-link>
          {{ $t("message.to_unlock_this_feature") }}
        </h3>
      </div>
      <div v-else class="drawerContent">
        <div
          :key="vocabCollection.id"
          v-for="vocabCollection in vocabCollections"
        >
          <div
            class="item"
            @click="() => {
                vocabularyIsCollected(vocabCollection.id)
                  ? removeVocabFromCollection(vocabCollection.id)
                  : addVocabToCollection(vocabCollection.id);
            }"
          >
            <span>{{ vocabCollection.title }}</span>
            <span
              v-if="vocabularyIsCollected(vocabCollection.id)"
              class="collectedIcon"
            >
              <i class="fas fa-star" />
            </span>
            <span v-else class="collectIcon">
              <i class="far fa-star" />
            </span>
          </div>
          <hr />
        </div>
        <div class="item" @click="openNewCollectionDialig">
          <span>{{ $t("flashCard.i18n12") }}</span>
          <i class="fas fa-plus" />
        </div>
        <hr />
      </div>
    </el-drawer>
    <CollectionFormDialog
      :visible="newCollectionDialigVisible"
      @closeNewCollectionDialig="closeNewCollectionDialig"
    />
  </div>
</template>

<script>
import formMixin from "@/mixins/form";
import CollectionFormDialog from "@/views/toefl/flashcards/CollectionFormDialog.vue";

export default {
  props: {
    vocabulary: {
      default: null,
      type: Object
    },
    opened: {
      default: false,
      type: Boolean
    }
  },
  components: {
    CollectionFormDialog
  },
  mixins: [formMixin],
  computed: {
    isFreeMember() {
      return this.$store.getters["user/isFreeMember"];
    },
    vocabCollections() {
      return this.$store.getters["flashcard/vocabCollections"];
    }
  },
  data() {
    return {
      newCollectionDialigVisible: false
    };
  },
  created() {
    this.$store.dispatch("flashcard/fetchVocabCollections");
  },
  methods: {
    openNewCollectionDialig() {
      this.newCollectionDialigVisible = true;
    },
    closeNewCollectionDialig() {
      this.newCollectionDialigVisible = false;
    },
    vocabularyIsCollected(collectionId) {
      if (this.vocabulary) {
        return this.vocabulary.vocab_collections.some(
          ({ id }) => id === collectionId
        );
      } else {
        return false;
      }
    },
    async addVocabToCollection(collectionId) {
      try {
        await this.$store.dispatch("flashcard/addVocabToCollection", {
          vocabularyId: this.vocabulary.id,
          collectionId
        });
        this.$store.dispatch(
          "flashcard/updateVocabularyStatus",
          this.vocabulary.id
        );
        this.$message.success(this.$t("flashCard.i18n13"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async removeVocabFromCollection(collectionId) {
      try {
        await this.$store.dispatch("flashcard/removeVocabFromCollection", {
          vocabularyId: this.vocabulary.id,
          collectionId
        });
        this.$store.dispatch(
          "flashcard/updateVocabularyStatus",
          this.vocabulary.id
        );
        this.$message.success(this.$t("flashCard.i18n14"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-drawer {
  width: 300px !important;
}

.drawerTitle {
  font-weight: bold;
}

.drawerContent {
  padding: 0px 20px 20px 20px;
  height: 100%;
}

.hasNoPermission {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #444444;
  text-align: center;
  .lock {
    font-size: 120px;
  }

  .upgradeText {
    font-size: 24px;
  }
}

.item {
  cursor: pointer;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .collectedIcon {
    color: orange;
  }
}

.item:hover {
  opacity: 0.6;

  .collectIcon {
    color: orange;
  }
}
</style>
