<template>
  <div class="vocabulariesCard">
    <div class="header">
      <div class="back">
        <span @click="backToPreviousPage" class="action-icon">
          <i class="fas fa-chevron-left" />
        </span>
      </div>
      <div class="title">
        <h1 @dblclick="addKeyboardEvent">
          {{ pageTitle }}
        </h1>
      </div>
    </div>
    <div v-if="Object.keys(vocabList).length !== 0">
      <FinishedVocabulariesCard
        v-if="isFinishedVocabularies"
        :vocabList="vocabList"
        :practiceDuring="practiceDuring"
        @restart="() => fetchShuffledVocab()"
      />
      <VocabulariesCard
        v-else
        :vocabList="vocabList"
        @finishedPractice="() => endTimer()"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import VocabulariesCard from "@/views/toefl/flashcards/VocabulariesCard.vue";
import FinishedVocabulariesCard from "@/views/toefl/flashcards/FinishedVocabulariesCard.vue";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - ` + this.CompanyName
    };
  },
  components: {
    VocabulariesCard,
    FinishedVocabulariesCard
  },
  computed: {
    _() {
      return _;
    },
    pageIsFlashCard() {
      return this.$route.name === "FlashcardsVocabulariesCard";
    },
    pageTitle() {
      return this.vocabList.list?.title || "";
    },
    vocabList() {
      return this.$store.getters["flashcard/vocabList"];
    },
    isFinishedVocabularies() {
      return (
        !this.timerInterval && (this.vocabList?.vocabularies || []).length === 0
      );
    }
  },
  data() {
    return {
      practiceDuring: 0,
      timerInterval: null
    };
  },
  async created() {
    const main = document.getElementsByClassName("el-main");
    main[0].style.padding = "0";
    const vocabularies = await this.fetchShuffledVocab();
    if (vocabularies.length === 0) {
      this.$confirm(this.$t("flashCard.i18n41"), this.$t("message.notice"), {
        confirmButtonText: this.$t("flashCard.i18n49"),
        cancelButtonText: this.$t("flashCard.i18n19"),
        type: "warning"
      })
        .then(() => { this.$router.push({ name: "Flashcards" }); })
        .catch(() => { this.$router.push({ name: "MyFlashcards" }); });
    };

    this.startTimer();
  },
  beforeRouteLeave(to, from, next) {
    document.removeEventListener("keydown", this.clickBorad);
    next();
  },
  methods: {
    startTimer() {
      this.timerInterval = setInterval(() => {
        this.practiceDuring += 1;
      }, 1000);
    },
    endTimer() {
      clearInterval(this.timerInterval);
      this.timerInterval = null;
    },
    backToPreviousPage() {
      this.$router.go(-1);
    },
    clickBorad({ code }) {
      switch (code) {
        case "Space":
          this.speakWord(this.currentVocabulary.vocab_front.word, "en-US");
          break;
        case "KeyQ":
          this.putTopVocabPackToCards("hard");
          break;
        case "KeyW":
          this.putTopVocabPackToCards("good");
          break;
        case "KeyE":
          this.putTopVocabPackToCards("easy");
          break;
        case "KeyT":
          this.removeVocabPackToCards();
          break;
        case "KeyR":
          if (this.displayVocabulary) {
            this.displayVocabulary = null;
          } else {
            this.openVocabularyDrawer();
          }
          break;
        default:
      }
    },
    addKeyboardEvent() {
      document.addEventListener("keydown", this.clickBorad);
    },
    async fetchShuffledVocab() {
      this.practiceDuring = 0;

      try {
        if (this.pageIsFlashCard) {
          await this.$store.dispatch(
            "flashcard/fetchVocabList",
            this.$route.query.listId
          );
        } else {
          await this.$store.dispatch(
            "flashcard/fetchCollectionVocabularies",
            this.$route.query.listId
          );
        }
      } catch (e) {
        if (e.data.message === "not_own_pro_plan") {
          this.$router.push({ name: "PageNotFound" });
        }
        return;
      }

      const shuffledVocabularies = this._.shuffle(this.vocabList.vocabularies);
      this.$store.commit("flashcard/setVocabList", {
        ...this.vocabList,
        vocabularies: shuffledVocabularies
      });
      return shuffledVocabularies;
    }
  }
};
</script>
<style lang="scss" scoped>
.header {
  width: 100vw;
  height: 60px;
  position: fixed;
  top: 0px;
  border-bottom: 1px solid #e0e0e0;
  display: grid;
  grid-template-columns: 52px 1fr;
  background-color: #fafafa;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .back {
    color: var(--themeColor);
    font-size: 32px;
  }

  .title {
    padding-right: 52px;
    h1 {
      font-size: 24px;
    }
  }
}
</style>