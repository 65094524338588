<template>
  <div v-if="currentVocabulary" class="vocabContainer">
    <div class="controlBtns">
      <span
        v-if="vocabularyIsCollected"
        class="collectIcon"
        @click="collectVocabDrawerVisible = true"
      >
        <i class="far fa-star" />
      </span>
      <span
        v-else
        class="collectedIcon"
        @click="collectVocabDrawerVisible = true"
      >
        <i class="fas fa-star" />
      </span>
    </div>
    <div @click="openVocabularyDrawer" class="vocabularyCard">
      <div class="word">
        <strong>{{ currentVocabulary.vocab_front.word }}</strong>
      </div>
      <div class="pronounce">
        <el-button
          type="primary"
          class="playBtn"
          @click.stop="speakWord(currentVocabulary.vocab_front.word, 'en-US')"
        >
          US&nbsp;<i class="fas fa-volume-up" />
        </el-button>
        <el-button
          type="primary"
          class="playBtn"
          @click.stop="speakWord(currentVocabulary.vocab_front.word, 'en-GB')"
        >
          UK&nbsp;<i class="fas fa-volume-up" />
        </el-button>
      </div>
      <div class="wordsRemaining">
        {{ $t("flashCard.i18n37") }}
        <span>{{ vocabularies.length }}</span>
      </div>
    </div>
    <div class="footer">
      <div class="hard" @click="() => putTopVocabPackToCards('hard')">
        {{ $t("flashCard.i18n38") }}
      </div>
      <div class="good" @click="() => putTopVocabPackToCards('good')">
        {{ $t("flashCard.i18n39") }}
      </div>
      <div class="easy" @click="() => putTopVocabPackToCards('easy')">
        {{ $t("flashCard.i18n40") }}
      </div>
      <div class="remove" @click="() => removeVocabPackToCards()">
        <span>
          <i class="fas fa-trash-alt" />
        </span>
      </div>
    </div>
    <VocabularyDrawer
      :opened="vocabularyDrawerVisible"
      :vocabulary="currentVocabulary"
      @closeVocabularyDrawer="() => (vocabularyDrawerVisible = false)"
    />
    <CollectVocabDrawer
      :opened="collectVocabDrawerVisible"
      :vocabulary="currentVocabulary"
      @closeCollectVocabDrawer="() => (collectVocabDrawerVisible = false)"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { speakWord } from "@/views/toefl/flashcards/speak.js";
import VocabularyDrawer from "@/views/toefl/flashcards/VocabularyDrawer.vue";
import CollectVocabDrawer from "@/views/toefl/flashcards/CollectVocabDrawer.vue";

export default {
  props: {
    vocabList: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VocabularyDrawer,
    CollectVocabDrawer
  },
  computed: {
    _() {
      return _;
    },
    vocabularies() {
      return this.vocabList?.vocabularies || [];
    },
    currentVocabulary() {
      return this.vocabularies[0] || null;
    },
    speakWord() {
      return speakWord;
    },
    vocabularyIsCollected() {
      return this.currentVocabulary.vocab_collections.length === 0;
    }
  },
  data() {
    return {
      displayVocabulary: null,
      vocabularyDrawerVisible: false,
      collectVocabDrawerVisible: false
    };
  },
  methods: {
    openVocabularyDrawer() {
      this.vocabularyDrawerVisible = true;
    },
    putTopVocabPackToCards(puttingWay) {
      this.$store.dispatch("flashcard/putTopVocabPackToCards", puttingWay);
    },
    async removeVocabPackToCards() {
      await this.$store.dispatch("flashcard/removeTopVocabulary");
      if (this.vocabularies.length === 0) {
        this.$emit("finishedPractice");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vocabContainer {
  width: 100vw;
  margin-top: -40px;
}

.controlBtns {
  width: 100vw;
  height: 30px;
  padding: 16px 20px;
  display: flex;
  justify-content: flex-end;

  .collectIcon {
    cursor: pointer;
    font-size: 36px;
  }

  .collectIcon:hover {
    color: orange;
  }

  .collectedIcon {
    cursor: pointer;
    font-size: 36px;
    color: orange;
  }
}

.vocabularyCard {
  cursor: pointer;
  width: 100vw;
  margin-top: 60px;
  min-height: calc(100vh - 60px - 90px - 96px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  position: relative;

  .word {
    font-size: 52px;
    margin-bottom: 64px;
  }

  .pronounce {
    display: flex;

    .playBtn {
      margin: 0px 16px;
      font-size: 24px;
    }
  }

  .wordsRemaining {
    position: absolute;
    bottom: 44px;
    right: 20px;

    span {
      color: red;
    }
  }
}

.footer {
  height: 96px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  div {
    cursor: pointer;
    border: 1px solid #e0e0e0;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
  }

  div:hover {
    opacity: 0.5;
  }

  .hard {
    border-top: 12px solid #D9534F;
    color: #D9534F;
  }

  .good {
    border-top: 12px solid #5Bc0DE;
    color: #5Bc0DE;
  }

  .easy {
    border-top: 12px solid #42A16A;
    color: #42A16A;
  }

  .remove {
    border-top: 12px solid #6e6e6e;
    color: #6e6e6e;
  }
}
@media (max-width: 992px) {
  .vocabContainer {
    margin-top: 0px;
  }
}
</style>
